<template>
  <v-row
    :class="$vuetify.theme.dark ? 'dark-background' : 'background'"
    align="center"
    justify="center"
    style="height: 100%"
    no-gutters
  >
    <v-col style="max-width: 600px">
      <v-card class="pt-10 pb-6 px-12 card-content">
        <v-row no-gutters>
          <v-col cols="12" class="text-center pb-4">
            <v-img
              alt="net2phone Logo"
              class="shrink mr-2"
              contain
              src="https://www.net2phone.com/hs-fs/hubfs/NB%202021/logos/n2p-logo-blue.png?width=432&name=n2p-logo-blue.png"
              transition="scale-transition"
              width="190"
            />
          </v-col>
          <v-col cols="12" class="pt-2 px-12">
            <v-form ref="login" lazy-validation>
              <v-text-field
                data-cy="userlogin"
                prepend-icon="mdi-account"
                :rules="[rules.required]"
                :label="$i18n.t('LOGIN.usernameLabel')"
                filled
                rounded
                dense
                v-model="username"
                @keyup.enter="doLogin()"
              />
              <v-text-field
                data-cy="passlogin"
                prepend-icon="mdi-lock"
                :label="$i18n.t('LOGIN.passwordLabel')"
                :rules="[rules.required]"
                v-model="password"
                filled
                rounded
                dense
                type="password"
                @keyup.enter="doLogin()"
              />
            </v-form>
          </v-col>
        </v-row>
        <v-row no-gutters justify="end">
          <v-col cols="4" class="pt-6">
            <v-btn
              data-cy="loginbtn"
              :loading="loadingButton"
              block
              large
              class="card-content__footer"
              dark
              depressed
              @click.prevent="doLogin"
            >
              {{ $i18n.t("LOGIN.signIn") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LoginModel from "./../models/login.js";

export default {
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const username = urlParams.get("u");
    const password = urlParams.get("p");
    this.username = username || "";
    this.password = password || "";
  },

  data() {
    return {
      username: "",
      password: "",
      showForgetPasswordModal: false,
      loadingButton: false,

      forgetEmail: "",
      forgetUsername: "",
      loadingForgetButton: false,

      rules: {
        required: (value) => !!value || "Required",
      },
    };
  },

  methods: {
    async doLogin() {
      if (this.$refs.login.validate()) {
        try {
          this.loadingButton = true;
          await LoginModel.login(this.username, this.password);
        } catch (error) {
          this.$notify({
            group: "feedback",
            duration: 5000,
            type: "error",
            title: "",
            text: this.$i18n.t("GENERAL.ERRORLOGIN"),
          });
        }
        this.loadingButton = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  background-image: url("../assets/background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: var(--v-primary-base);
}

.dark-background {
  background-image: url("../assets/background.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #272727;
}

.card-content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border-top: 5px solid #d1404a;
  .card-content__footer {
    background-color: #d1404a !important;
    border-radius: 8px;
  }
}

.password-reset__content {
  box-shadow: 10px 10px 43px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  width: 450px;
}
</style>
